.dropdown-menu.dropdown-menu-anim {
  animation: dropdown-menu-fade-in 0.3s ease 1, dropdown-menu-move-up 0.3s ease-out 1;
}
.dropup .dropdown-menu.dropdown-menu-anim {
  animation: dropdown-menu-fade-in 0.3s ease 1, dropdown-menu-move-down 0.3s ease-out 1;
}
@keyframes dropdown-menu-fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes dropdown-menu-move-down {
  0% {
    margin-top: -10px;
  }
  to {
    margin-top: 0;
  }
}
@keyframes dropdown-menu-move-up {
  0% {
    margin-top: 10px;
  }
  to {
    margin-top: 0;
  }
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ebedf2;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #9aabff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.25);
}
.form-control::placeholder {
  color: #a7abc3;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #f7f8fa;
  opacity: 1;
}
.col-form-label {
  padding-top: calc(0.65rem + 1px);
  padding-bottom: calc(0.65rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #6c7293;
  background-color: initial;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(1.5em + 2.3rem + 2px);
  padding: 1.15rem 1.65rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #a7abc3;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #0abb87;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #fff;
  background-color: #0abb87;
  border-radius: 3px;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #0abb87;
  padding-right: calc(1.5em + 1.3rem);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%230abb87' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 100% calc(0.375em + 0.325rem);
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #0abb87;
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.25);
}
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
  display: block;
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.3rem);
  background-position: top calc(0.375em + 0.325rem) right calc(0.375em + 0.325rem);
}
.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #0abb87;
  padding-right: calc(0.75em + 2.975rem);
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
      no-repeat right 1rem center/8px 10px,
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%230abb87' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E")
      #fff no-repeat center left 2rem / calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #0abb87;
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.25);
}
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip {
  display: block;
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #0abb87;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #0abb87;
}
.custom-control-input.is-valid ~ .custom-control-label:before,
.was-validated .custom-control-input:valid ~ .custom-control-label:before {
  border-color: #0abb87;
}
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid:checked ~ .custom-control-label:before,
.was-validated .custom-control-input:valid:checked ~ .custom-control-label:before {
  border-color: #0debaa;
  background-color: #0debaa;
}
.custom-control-input.is-valid:focus ~ .custom-control-label:before,
.was-validated .custom-control-input:valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.25);
}
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label:before,
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label:before,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #0abb87;
}
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #0abb87;
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fd397a;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #fff;
  background-color: #fd397a;
  border-radius: 3px;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #fd397a;
  padding-right: calc(1.5em + 1.3rem);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fd397a' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23fd397a' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 100% calc(0.375em + 0.325rem);
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #fd397a;
  box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.25);
}
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.3rem);
  background-position: top calc(0.375em + 0.325rem) right calc(0.375em + 0.325rem);
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #fd397a;
  padding-right: calc(0.75em + 2.975rem);
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
      no-repeat right 1rem center/8px 10px,
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fd397a' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23fd397a' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E")
      #fff no-repeat center left 2rem / calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #fd397a;
  box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.25);
}
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip {
  display: block;
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #fd397a;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #fd397a;
}
.custom-control-input.is-invalid ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid ~ .custom-control-label:before {
  border-color: #fd397a;
}
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before {
  border-color: #fe6b9c;
  background-color: #fe6b9c;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before,
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label:before,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #fd397a;
}
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #fd397a;
  box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.25);
}
@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
  }
  .form-inline .form-group,
  .form-inline label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    flex: 0 0 auto;
    flex-flow: row wrap;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.alert {
  display: flex;
  align-items: stretch;
  padding: 1rem 2rem;
  border-radius: 3px;
  margin: 0 0 20px;
}
.alert .alert-icon {
  display: flex;
  align-items: center;
  padding: 0 1.25rem 0 0;
}
.alert .alert-icon i {
  font-size: 2.4rem;
}
.alert .alert-text {
  align-self: center;
  flex-grow: 1;
}
.alert .alert-close {
  padding: 0 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.alert .alert-close .close {
  text-shadow: none !important;
}
.alert .alert-close i {
  opacity: 0.8;
  transition: all 0.3s ease;
}
.alert .alert-close i:hover {
  transition: all 0.3s ease;
  opacity: 1;
}
.alert.alert-primary {
  background: #5867dd;
  border: 1px solid #5867dd;
  color: #fff;
}
.alert.alert-primary .alert-icon i,
.alert.alert-primary .alert-text {
  color: #fff;
}
.alert.alert-primary .alert-text hr {
  border-top-color: #fff;
  opacity: 0.4;
}
.alert.alert-primary .alert-close i {
  color: #fff;
}
.alert.alert-success {
  background: #0abb87;
  border: 1px solid #0abb87;
  color: #fff;
}
.alert.alert-success .alert-icon i,
.alert.alert-success .alert-text {
  color: #fff;
}
.alert.alert-success .alert-text hr {
  border-top-color: #fff;
  opacity: 0.4;
}
.alert.alert-success .alert-close i {
  color: #fff;
}
.alert.alert-info {
  background: #5578eb;
  border: 1px solid #5578eb;
  color: #fff;
}
.alert.alert-info .alert-icon i,
.alert.alert-info .alert-text {
  color: #fff;
}
.alert.alert-info .alert-text hr {
  border-top-color: #fff;
  opacity: 0.4;
}
.alert.alert-info .alert-close i {
  color: #fff;
}
.alert.alert-warning {
  background: #ffb822;
  border: 1px solid #ffb822;
  color: #111;
}
.alert.alert-warning .alert-icon i,
.alert.alert-warning .alert-text {
  color: #111;
}
.alert.alert-warning .alert-text hr {
  border-top-color: #111;
  opacity: 0.4;
}
.alert.alert-warning .alert-close i {
  color: #111;
}
.alert.alert-secondary .alert-icon i {
  color: #a7abc3;
}
.alert.alert-secondary .alert-text {
  color: #6c7293;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-text span {
  line-height: 2.5rem;
}
.alert-primary {
  color: #2e3673;
  background-color: #dee1f8;
  border-color: #d0d4f5;
}
.alert-primary hr {
  border-top-color: #bbc1f1;
}
.alert-secondary {
  color: #75757c;
  background-color: #f9f9fc;
  border-color: #f7f7fb;
}
.alert-secondary hr {
  border-top-color: #e6e6f3;
}
.alert-success {
  color: #056146;
  background-color: #cef1e7;
  border-color: #baecdd;
}
.alert-success hr {
  border-top-color: #a6e6d3;
}
.alert-info {
  color: #2c3e7a;
  background-color: #dde4fb;
  border-color: #cfd9f9;
}
.alert-info hr {
  border-top-color: #b8c7f6;
}
.alert-warning {
  color: #856012;
  background-color: #fff1d3;
  border-color: #ffebc1;
}
.alert-warning hr {
  border-top-color: #ffe3a8;
}
.alert.alert-solid-brand {
  background: rgba(54, 108, 243, 0.1);
  border-color: transparent;
  color: #366cf3;
}
.alert.alert-solid-brand .alert-close i,
.alert.alert-solid-brand .alert-icon i,
.alert.alert-solid-brand .alert-text {
  color: #366cf3;
}
.alert.alert-solid-dark {
  background: rgba(40, 42, 60, 0.1);
  border-color: transparent;
  color: #282a3c;
}
.alert.alert-solid-dark .alert-close i,
.alert.alert-solid-dark .alert-icon i,
.alert.alert-solid-dark .alert-text {
  color: #282a3c;
}
.alert.alert-success {
  background: #1dc9b7;
  border: 1px solid #1dc9b7;
}
.alert.alert-solid-success {
  background: rgba(29, 201, 183, 0.1);
  border-color: transparent;
  color: #1dc9b7;
}
.alert.alert-solid-success .alert-close i,
.alert.alert-solid-success .alert-icon i,
.alert.alert-solid-success .alert-text {
  color: #1dc9b7;
}
.alert.alert-solid-warning {
  background: rgba(255, 184, 34, 0.1);
  border-color: transparent;
  color: #ffb822;
}
.alert.alert-solid-warning .alert-close i,
.alert.alert-solid-warning .alert-icon i,
.alert.alert-solid-warning .alert-text {
  color: #ffb822;
}
.alert.alert-solid-danger {
  background: rgba(253, 57, 122, 0.1);
  border-color: transparent;
  color: #fd397a;
}
.alert.alert-solid-danger .alert-close i,
.alert.alert-solid-danger .alert-icon i,
.alert.alert-solid-danger .alert-text {
  color: #fd397a;
}
.alert.alert-secondary .alert-icon i {
  color: #74788d;
}
.alert.alert-secondary .alert-text {
  color: #595d6e;
}
.alert.alert-bold .alert-text {
  font-weight: 500;
}
