.kt-bg-light-success {
  background-color: rgba(10, 187, 135, 0.1) !important;
}

.kt-bg-light-brand {
  background-color: rgba(93, 120, 255, 0.1) !important;
}

.kt-media.kt-media--circle img {
  border-radius: 50%;
}

.kt-media.kt-media--sm img {
  width: 100%;
  max-width: 30px;
  height: 30px;
}

.kt-chat {
  .kt-portlet__head {
    align-items: center;
    flex-grow: 1;
  }

  .kt-chat__head {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-grow: 1;

    .kt-chat__label {
      .kt-chat__title {
        font-size: 1.1rem;
        color: rgb(72, 70, 91);
        font-weight: 600;
        margin: 0;
        padding: 0;

        &:hover {
          color: rgb(93, 120, 255);
          transition: color 0.3s ease;
        }
      }

      .kt-chat__status {
        color: rgb(116, 120, 141);
        font-size: 0.9rem;
        font-weight: 500;
        display: block;
      }
    }

    .kt-chat__left {
      flex-grow: 1;
      text-align: left;
    }

    .kt-chat__right {
      flex-grow: 1;
      text-align: right;
    }

    .kt-chat__center {
      flex-grow: 1;
      text-align: center;
    }
  }

  .kt-chat__messages {
    .kt-chat__message {
      margin-bottom: 1rem;

      .kt-chat__user {
        .kt-chat__username {
          padding: 0 0.4rem;
          font-size: 1.1rem;
          color: rgb(72, 70, 91);
          font-weight: 500;

          &:hover {
            color: rgb(93, 120, 255);
            transition: color 0.3s ease;
          }
        }

        .kt-chat__datetime {
          color: rgb(116, 120, 141);
          font-size: 0.9rem;
          font-weight: 400;
        }
      }

      .kt-chat__text {
        display: inline-block;
        padding: 1.2rem 1.7rem;
        padding: 1.1rem 1.6rem 1.1rem 3.5rem;
        margin-top: 0.8rem;
        color: rgb(108, 114, 147);
        font-weight: 500;
        font-size: 1.1rem;
        border-radius: 4px;

        b {
          color: rgb(89, 93, 110);
          font-weight: 0;
        }
      }

      &.kt-chat__message--right {
        text-align: left;

        .kt-chat__text {
          text-align: right;
          padding: 1.1rem 1.6rem 1.1rem 3.5rem;
        }
      }
    }

    &.kt-chat__messages--solid {
      width: 100%;

      .kt-chat__message {
        padding: 1.3rem 1.7rem;
        margin: 1.5rem 0;
        display: inline-block;
        border-radius: 4px;

        &:first-child {
          margin-top: 0;
        }

        .kt-chat__text {
          padding: 0;
          margin-top: 0.5rem;
          background: none !important;
        }

        &.kt-chat__message--right {
          float: right;
        }

        &.kt-chat__message--brand {
          background: rgba(93, 120, 255, 0.1);
        }

        &.kt-chat__message--light {
          background: rgba(255, 255, 255, 0.1);
        }

        &.kt-chat__message--dark {
          background: rgba(40, 42, 60, 0.1);
        }

        &.kt-chat__message--primary {
          background: rgba(88, 103, 221, 0.1);
        }

        &.kt-chat__message--success {
          background: rgba(10, 187, 135, 0.1);
        }

        &.kt-chat__message--info {
          background: rgba(85, 120, 235, 0.1);
        }

        &.kt-chat__message--warning {
          background: rgba(255, 184, 34, 0.1);
        }

        &.kt-chat__message--danger {
          background: rgba(253, 57, 122, 0.1);
        }
      }
    }
  }

  .kt-chat__input {
    .kt-chat__editor {
      textarea {
        width: 100%;
        border: 0;
        outline: none;
        background-color: transparent;
        color: rgb(162, 165, 185);

        &::placeholder {
          color: rgb(162, 165, 185);
          opacity: 1;
        }
      }
    }

    .kt-chat__toolbar {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .kt_chat__tools {
        a {
          i {
            margin-right: 1.1rem;
            color: rgb(205, 209, 233);
            font-size: 1.4rem;

            &:hover {
              color: rgb(93, 120, 255);
              transition: color 0.3s ease;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .kt-chat__messages {
      .kt-chat__message {
        margin-bottom: 0.75rem;

        .kt-chat__user {
          .kt-chat__username {
            padding: 0 0.25rem;
            font-size: 1rem;
          }
        }

        .kt-chat__text {
          display: inline-block;
          padding: 0.75rem 1rem;
          margin-top: 0.5rem;
        }
      }

      .kt-chat__message.kt-chat__message--right {
        .kt-chat__text {
          padding: 0.75rem 1rem;
        }
      }
    }

    .kt-chat__input {
      .kt-chat__toolbar {
        margin-top: 0.75rem;

        .kt_chat__tools {
          a {
            i {
              margin-right: 0.75rem;
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
}

.kt-todo__files {
  padding-top: 10px;

  &.kt-todo__file {
    display: block;
    padding-top: 0.7rem;

    &i {
      padding-right: 0.5rem;
      font-size: 15px;
    }

    &a {
      color: rgb(116, 120, 141);
      font-weight: 500;

      &:hover {
        color: rgb(93, 120, 255);
        transition: all 0.3s ease;
      }
    }
  }
}
