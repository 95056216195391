@media screen and (max-width: 600px) {
  table {
    border: 0;

    & caption {
      font-size: 1.3em;
    }

    & thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    & tr {
      border-bottom: 3px solid rgb(221, 221, 221);
      display: block;
      margin-bottom: 0.625em;
    }

    & td {
      border-bottom: 1px solid rgb(221, 221, 221);
      display: block;
      text-align: left;

      &::before {
        /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
        content: attr(data-label);
        float: right;
        font-weight: bold;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .kt-subheader .kt-subheader__toolbar {
    display: block !important;

    button {
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 0 !important;
    }
  }
}
