/* rtl:begin:ignore */
$dark-grey: rgb(8, 22, 51);
$lighter-grey: rgb(39, 41, 45);
$yellow: rgb(226, 187, 45);
$yellow2: rgb(183, 154, 47);

body {
  overflow-x: hidden;
}

.error-page {
  background: rgb(8, 22, 51);
  min-height: 100%;
}

.police-tape {
  background: $yellow,
    linear-gradient(
      180deg,
      lighten($yellow, 20%) 0%,
      $yellow 5%,
      $yellow 90%,
      lighten($yellow, 5%) 95%,
      darken($yellow, 50%) 100%
    );
  padding: 0.125em;
  font-size: 2.5em;
  text-align: center;
  white-space: nowrap;
}

.police-tape--1 {
  transform: rotate(10deg);
  position: absolute;
  top: 50%;
  left: -5%;
  right: 8%;
  z-index: 2;
  margin-top: 0;
  color: rgb(34, 34, 34);
}

.police-tape--2 {
  transform: rotate(-8deg);
  position: absolute;
  top: 50%;
  left: -9%;
  right: -5%;
  color: rgb(34, 34, 34);
}

.ghost {
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  height: 100vh;
}

.ghost--columns {
  display: flex;
  flex-grow: 1;
  flex-basis: 200px;
  align-content: stretch;
}

.ghost--navbar {
  flex: 0 0 60px;
  background: linear-gradient(0deg, $lighter-grey 0, $lighter-grey 10px, transparent 10px);
  border-bottom: 2px solid $dark-grey;
}

.ghost--column {
  flex: 1 0 30%;
  border-width: 0;
  border-style: solid;
  border-color: $lighter-grey;
  border-left-width: 10px;
  background-color: darken($dark-grey, 2%);

  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      .code {
        @for $j from 1 through 4 {
          &:nth-child(#{$j}) {
            // $rotation: (5 - random(10)) + deg;
            // transform: translateY(0px) rotate($rotation);
            $spacing: calc(random(9) / 2) + 1em;

            margin-left: $spacing;
          }
        }
      }
    }
  }
}

.ghost--main {
  background-color: $dark-grey;
  border-top: 15px solid lighten($lighter-grey, 4%);
  flex: 1 0 100px;
}

.code {
  display: block;
  width: 100px;
  background-color: $lighter-grey;
  height: 1em;
  margin: 1em;

  .ghost--main & {
    height: 2em;
    width: 200px;
  }
}

/* rtl:end:ignore */
