/* rtl:ignore */
.form-control.vue-tel-wrapper-extended {
  direction: ltr;
  display: flex;
  text-align: left !important;
  border: 1px solid rgb(235, 237, 242) !important;

  &:focus-within {
    border-color: rgb(154, 171, 255) !important;
    box-shadow: unset !important;
  }

  &.is-invalid,
  &[has-validation-error='true'] {
    border-color: rgb(253, 57, 122) !important;
    padding: 0.65rem 1rem !important;
  }

  // .vti__dropdown-list {
  //   text-align: left !important;
  // }
}
