@import 'multiselect';
@import 'selectpage';
@import 'vueTelInput';

//vue-scrolly

/* rtl:ignore */
[dir='rtl'] {
  .scrolly-bar.axis-y {
    right: unset !important;
    left: 0;
  }
}

.modal {
  .modal-loading {
    .modal-content::before {
      content: '';
      position: absolute;
      background-color: rgba(255, 255, 255, 0.8);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 99999;
    }
  }
}

.vue-treeselect {
  &.is-invalid {
    .vue-treeselect__control {
      border-color: rgb(253, 57, 122) !important;
    }
  }
}
