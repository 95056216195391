.kt-pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  & .kt-pagination__links,
  & .kt-pagination__links li a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .kt-pagination__links {
    list-style-type: none;
    margin: 0;
    padding: 0;

    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
      border-radius: 4px;

      &:first-of-type {
        margin-right: 0;
      }

      & a {
        font-weight: 500;
        color: rgb(116, 120, 141);
        font-size: 1rem;
        padding: 0 0.2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        min-width: 30px;
        min-height: 30px;
        margin: 0;
      }
    }

    & .kt-pagination__link--active a {
      color: rgb(255, 255, 255);
    }
  }

  & .kt-pagination__toolbar {
    margin: 0;
    display: flex;
    align-items: center;
    padding: 0;

    & .form-control {
      padding: 0;
      margin-right: 10px;
      height: 30px;
      font-size: 1.1rem;
      font-weight: 500;
      line-height: 1;
      outline: none;
      border: none;
    }

    & .pagination__desc {
      flex-grow: 1;
      font-weight: 500;
      color: rgb(116, 120, 141);
    }
  }

  &.kt-pagination--brand .kt-pagination__links .kt-pagination__link--first,
  &.kt-pagination--brand .kt-pagination__links .kt-pagination__link--next,
  &.kt-pagination--brand .kt-pagination__links .kt-pagination__link--prev,
  &.kt-pagination--brand .kt-pagination__links .kt-pagination__link--last {
    background: rgba(93, 120, 255, 0.1);
  }

  &.kt-pagination--brand .kt-pagination__links .kt-pagination__link--active {
    background: rgb(93, 120, 255);
  }

  &.kt-pagination--brand .kt-pagination__links a {
    &[disabled='disabled'],
    &.disabled {
      color: rgb(147, 162, 221);
      background: rgb(240, 243, 255);
      opacity: 0.3;
    }
  }

  &.kt-pagination--brand .kt-pagination__links li:hover {
    background: rgb(93, 120, 255);
    transition: color 0.3s ease;

    & a {
      color: rgb(255, 255, 255);

      & i {
        color: rgb(255, 255, 255) !important;
      }
    }
  }

  &.kt-pagination--brand .kt-pagination__toolbar .form-control {
    background: rgba(93, 120, 255, 0.1);
  }

  &.kt-pagination--light .kt-pagination__links .kt-pagination__link--first,
  &.kt-pagination--light .kt-pagination__links .kt-pagination__link--next,
  &.kt-pagination--light .kt-pagination__links .kt-pagination__link--prev,
  &.kt-pagination--light .kt-pagination__links .kt-pagination__link--last {
    background: rgba(255, 255, 255, 0.1);
  }

  &.kt-pagination--light .kt-pagination__links .kt-pagination__link--active {
    background: rgb(255, 255, 255);
  }

  &.kt-pagination--light .kt-pagination__links li:hover {
    background: rgb(255, 255, 255);
    transition: color 0.3s ease;

    & a {
      color: rgb(255, 255, 255);

      & i {
        color: rgb(255, 255, 255) !important;
      }
    }
  }

  &.kt-pagination--light .kt-pagination__toolbar .form-control {
    background: rgba(255, 255, 255, 0.1);
  }

  &.kt-pagination--dark .kt-pagination__links .kt-pagination__link--first,
  &.kt-pagination--dark .kt-pagination__links .kt-pagination__link--next,
  &.kt-pagination--dark .kt-pagination__links .kt-pagination__link--prev,
  &.kt-pagination--dark .kt-pagination__links .kt-pagination__link--last {
    background: rgba(40, 42, 60, 0.1);
  }

  &.kt-pagination--dark .kt-pagination__links .kt-pagination__link--active {
    background: rgb(40, 42, 60);
  }

  &.kt-pagination--dark .kt-pagination__links li:hover {
    background: rgb(40, 42, 60);
    transition: color 0.3s ease;

    & a {
      color: rgb(255, 255, 255);

      & i {
        color: rgb(255, 255, 255) !important;
      }
    }
  }

  &.kt-pagination--dark .kt-pagination__toolbar .form-control {
    background: rgba(40, 42, 60, 0.1);
  }

  &.kt-pagination--primary .kt-pagination__links .kt-pagination__link--first,
  &.kt-pagination--primary .kt-pagination__links .kt-pagination__link--next,
  &.kt-pagination--primary .kt-pagination__links .kt-pagination__link--prev,
  &.kt-pagination--primary .kt-pagination__links .kt-pagination__link--last {
    background: rgba(88, 103, 221, 0.1);
  }

  &.kt-pagination--primary .kt-pagination__links .kt-pagination__link--active {
    background: rgb(88, 103, 221);
  }

  &.kt-pagination--primary .kt-pagination__links li:hover {
    background: rgb(88, 103, 221);
    transition: color 0.3s ease;

    & a {
      color: rgb(255, 255, 255);

      & i {
        color: rgb(255, 255, 255) !important;
      }
    }
  }

  &.kt-pagination--primary .kt-pagination__toolbar .form-control {
    background: rgba(88, 103, 221, 0.1);
  }

  &.kt-pagination--success .kt-pagination__links .kt-pagination__link--first,
  &.kt-pagination--success .kt-pagination__links .kt-pagination__link--next,
  &.kt-pagination--success .kt-pagination__links .kt-pagination__link--prev,
  &.kt-pagination--success .kt-pagination__links .kt-pagination__link--last {
    background: rgba(10, 187, 135, 0.1);
  }

  &.kt-pagination--success .kt-pagination__links .kt-pagination__link--active {
    background: rgb(10, 187, 135);
  }

  &.kt-pagination--success .kt-pagination__links li:hover {
    background: rgb(10, 187, 135);
    transition: color 0.3s ease;

    & a {
      color: rgb(255, 255, 255);

      & i {
        color: rgb(255, 255, 255) !important;
      }
    }
  }

  &.kt-pagination--success .kt-pagination__toolbar .form-control {
    background: rgba(10, 187, 135, 0.1);
  }

  &.kt-pagination--info .kt-pagination__links .kt-pagination__link--first,
  &.kt-pagination--info .kt-pagination__links .kt-pagination__link--next,
  &.kt-pagination--info .kt-pagination__links .kt-pagination__link--prev,
  &.kt-pagination--info .kt-pagination__links .kt-pagination__link--last {
    background: rgba(85, 120, 235, 0.1);
  }

  &.kt-pagination--info .kt-pagination__links .kt-pagination__link--active {
    background: rgb(85, 120, 235);
  }

  &.kt-pagination--info .kt-pagination__links li:hover {
    background: rgb(85, 120, 235);
    transition: color 0.3s ease;

    & a {
      color: rgb(255, 255, 255);

      & i {
        color: rgb(255, 255, 255) !important;
      }
    }
  }

  &.kt-pagination--info .kt-pagination__toolbar .form-control {
    background: rgba(85, 120, 235, 0.1);
  }

  &.kt-pagination--warning .kt-pagination__links .kt-pagination__link--first,
  &.kt-pagination--warning .kt-pagination__links .kt-pagination__link--next,
  &.kt-pagination--warning .kt-pagination__links .kt-pagination__link--prev,
  &.kt-pagination--warning .kt-pagination__links .kt-pagination__link--last {
    background: rgba(255, 184, 34, 0.1);
  }

  &.kt-pagination--warning .kt-pagination__links .kt-pagination__link--active {
    background: rgb(255, 184, 34);
  }

  &.kt-pagination--warning .kt-pagination__links li:hover {
    background: rgb(255, 184, 34);
    transition: color 0.3s ease;

    & a {
      color: rgb(255, 255, 255);

      & i {
        color: rgb(255, 255, 255) !important;
      }
    }
  }

  &.kt-pagination--warning .kt-pagination__toolbar .form-control {
    background: rgba(255, 184, 34, 0.1);
  }

  &.kt-pagination--danger .kt-pagination__links .kt-pagination__link--first,
  &.kt-pagination--danger .kt-pagination__links .kt-pagination__link--next,
  &.kt-pagination--danger .kt-pagination__links .kt-pagination__link--prev,
  &.kt-pagination--danger .kt-pagination__links .kt-pagination__link--last {
    background: rgba(253, 57, 122, 0.1);
  }

  &.kt-pagination--danger .kt-pagination__links .kt-pagination__link--active {
    background: rgb(253, 57, 122);
  }

  &.kt-pagination--danger .kt-pagination__links li:hover {
    background: rgb(253, 57, 122);
    transition: color 0.3s ease;

    & a {
      color: rgb(255, 255, 255);

      & i {
        color: rgb(255, 255, 255) !important;
      }
    }
  }

  &.kt-pagination--danger .kt-pagination__toolbar .form-control {
    background: rgba(253, 57, 122, 0.1);
  }

  &.kt-pagination--circle .kt-pagination__links li {
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
  }

  &.kt-pagination--lg .kt-pagination__links li a {
    font-size: 1.3rem;
    min-width: 35px;
    min-height: 35px;
  }

  &.kt-pagination--lg .kt-pagination__toolbar .form-control {
    height: 35px;
    font-size: 1.2rem;
    padding: 0.2rem 0 0.2rem 0.2rem;
  }

  &.kt-pagination--sm .kt-pagination__links li a {
    font-size: 1rem;
    min-width: 25px;
    min-height: 25px;
  }

  &.kt-pagination--sm .kt-pagination__toolbar .form-control {
    height: 25px;
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .kt-pagination .kt-pagination__links {
    display: flex;
    flex-wrap: wrap;

    & li {
      margin: 0.3rem 0.5rem 0.3rem 0;

      & a {
        font-size: 0.9rem;
        min-width: 25px;
        min-height: 25px;
      }
    }
  }

  .kt-pagination .kt-pagination__toolbar .form-control {
    height: 25px;
    font-size: 0.9rem;
    padding: 0.2rem 0 0.2rem 0.2rem;
  }
}
