.popover,.tui-editor-contents p {
  font-family: var(--font-family-sans-serif, IRANSans);
}

.info-tooltip-icon{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
