.multiselect {
  &.is-invalid {
    border: 1px solid rgb(253, 57, 122) !important;
    border-radius: 4px !important;
    color: rgb(253, 57, 122) !important;
  }
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: rgba(88, 103, 221, 0.1);
  color: rgb(88, 103, 221);
}

.input-group > .multiselect {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;

  &:not(:first-child) {
    .multiselect__tags {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &:not(:last-child) {
    .multiselect__tags {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
