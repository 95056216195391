/* rtl:begin:ignore */
:root[dir='ltr'] {
  body {
    direction: ltr;
    text-align: left;
    left: calc(-1 * var(--scrollbar-width, 0));
    font-family: poppins, sans-serif;
    font-weight: 500;
  }
  .kt-aside--enabled {
    &.kt-subheader--fixed .kt-subheader {
      left: var(--aside-width, 240px);
      right: 0;
    }
    .kt-header.kt-header--fixed {
      left: var(--aside-width, 240px);
      right: 0;
    }
  }
  .kt-aside--fixed {
    .kt-aside {
      left: 0;
      right: auto;
    }
    .kt-wrapper {
      padding-left: var(--aside-width, 240px);
      padding-right: 0;
    }
  }
  .dropdown-item {
    text-align: left;
  }
  .kt-radio-inline .kt-radio:last-child {
    margin-left: 15px;
    margin-right: 0;
  }
  [class^='fa-angle-'],
  [class*=' fa-angle-'] {
    &::before {
      transform: scaleX(-1);
    }
  }
}

/* rtl:end:ignore */
