//alignments
.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}

.w80 {
  width: 80px;
}

/////////////////

.ltr {
  direction: ltr;
  text-align: left;
}

label.vpd-icon-btn {
  margin-bottom: 0 !important;
}

.empty-row {
  text-align: center;
  line-height: 4rem;
}

.flag {
  border-radius: 4px;
  width: 20px;
  display: inline-block;
}

.table-horizontal {
  & tr > td:nth-child(2n + 1) {
    background: rgb(247, 248, 251);
  }
}

.rotary-element {
  transition: 0.5s ease-in-out all;

  &.rotate-clockwise-180 {
    transform: rotate(180deg);
  }

  &.rotate-counter-clockwise-180 {
    transform: rotate(-180deg);
  }
}

.perfect-scrollbar-aside {
  max-height: 90vh;
  position: relative;
}

.bg-notification {
  background-image: url('~@/assets/images/topbar/bg-1.jpg');
}

.modal .modal-content .modal-header .close::before {
  display: none;
}
.form-control,
.multiselect__tags,
.custom-select {
  border: 1px solid #ddd;
}
.farsi-font {
  font-family: var(--font-family-sans-serif, IRANSans);
  text-align: right;
}
.floating-btn {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 10px;
  left: 10px;
  border-radius: 50px;
  text-align: center;
  i {
    color: #5d78ff;
  }
}

#kt_header > .navbar {
  padding-right: var(--aside-width);
}

#kt_wrapper {
  padding-top: calc(54px + var(--header-height, 50px));
  // min-height: 100vh;
}

body,
:root {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: 300;
  font-family: var(--font-family-sans-serif, IRANSans);
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

@media (min-width: 769px) and (max-width: 1024px) {
  body,
  :root {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  body,
  :root {
    font-size: 12px;
  }
}

@media (min-width: 1400px) {
  .modal-dialog.modal-xl {
    max-width: 1299px;
  }
  .modal-dialog.modal-xxl {
    max-width: 1600px !important;
  }
}

.av-details {
  padding: 1.1rem 25px 0 25px;
}

.av-details .av-details__item.description {
  justify-content: start;
  padding: 1.1rem 0;
  border-top: 0.07rem dashed #ebedf2;
}

.av-details .av-details__item.description .av-details__info .av-details__title {
  margin-bottom: 0;
  margin-left: 0.5rem;
}
.disabled {
  background: #eceaea;
  opacity: 0.7;
  a {
    color: gray;
  }
}

.ql-editor {
  font-size: 14px;
  font-weight: 300;
  font-family: var(--font-family-sans-serif, IRANSans);
  * {
    line-height: 25px;
  }
}

.businessPartyRolesMultiselect {
  .multiselect__content-wrapper {
    top: 40px;
  }
  .multiselect__select {
    background-color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.businessPartyRolesMultiselect.opneOnTopBusinessPartyRolesMultiselect {
  .multiselect__content-wrapper {
    bottom: 40px;
  }
}
