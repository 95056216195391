.profile-icon {
  position: relative;

  i {
    font-size: 60px;
    background: rgb(242, 243, 247);
    border-radius: 10px;
    padding: 10px;
    color: rgb(93, 120, 255);
  }

  .success-flag {
    position: absolute;
    right: -20px;
    top: -20px;
    font-size: 30px;
    background: none;
  }
}

.profile-flag {
  width: 30px;
  border-radius: 5px;
}

.flag-badges i {
  font-size: 1.3rem;

  &.la-expeditedssl {
    font-size: 1.7rem;
  }

  &.la-exchange::before {
    position: relative;
    right: 2px;
    top: 2px;
  }
}

.kt-widget.kt-widget--user-profile-3 {
  .kt-widget__top {
    display: flex;
    align-items: flex-start;

    .kt-widget__media {
      margin-top: 0.2rem;

      img {
        width: 110px;
        border-radius: 8px;
      }
    }

    .kt-widget__pic {
      margin-top: 0.2rem;
      width: 100%;
      max-width: 110px;
      height: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      border-radius: 8px;

      &.kt-widget__pic--brand {
        background: rgba(93, 120, 255, 0.1);
      }

      &.kt-widget__pic--light {
        background: rgba(255, 255, 255, 0.1);
      }

      &.kt-widget__pic--dark {
        background: rgba(40, 42, 60, 0.1);
      }

      &.kt-widget__pic--primary {
        background: rgba(88, 103, 221, 0.1);
      }

      &.kt-widget__pic--success {
        background: rgba(10, 187, 135, 0.1);
      }

      &.kt-widget__pic--info {
        background: rgba(85, 120, 235, 0.1);
      }

      &.kt-widget__pic--warning {
        background: rgba(255, 184, 34, 0.1);
      }

      &.kt-widget__pic--danger {
        background: rgba(253, 57, 122, 0.1);
      }
    }

    .kt-widget__content {
      width: 100%;
      padding-right: 1.7rem;

      .kt-widget__head {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        .kt-widget__user {
          display: flex;
          align-items: center;
        }

        .kt-widget__username {
          font-size: 1.3rem;
          color: rgb(70, 68, 87);
          font-weight: 500;
          align-items: center;

          &:hover {
            color: rgb(93, 120, 255);
            transition: color 0.3s ease;
          }

          i {
            font-size: 1.2rem;
            color: rgb(87, 201, 116);
            padding-right: 0.5rem;
          }
        }

        .kt-widget__action .btn {
          font-weight: 600;
          margin-right: 0.5rem;
        }
      }

      .kt-widget__subhead {
        padding: 0.6rem 0 0.8rem 0;

        a {
          color: rgb(167, 171, 195);
          font-weight: 500;

          &:hover {
            color: rgb(93, 120, 255);
            transition: color 0.3s ease;
          }

          i {
            padding-left: 0.5rem;
          }
        }
      }

      .kt-widget__info {
        display: flex;
        flex-flow: row wrap;

        .kt-widget__desc {
          color: rgb(108, 114, 147);
          font-weight: 400;
          padding-left: 1rem;
          flex-grow: 1;
          margin-bottom: 0.5rem;
        }

        .kt-widget__progress {
          display: flex;
          align-items: center;
          max-width: 500px;
          width: 100%;
          margin: 0.1rem 0;

          .kt-widget__text {
            padding-right: 1rem;
            color: rgb(108, 114, 147);
            font-weight: 500;
          }

          .kt-widget__stats {
            padding-left: 1rem;
            color: rgb(70, 68, 87);
            font-weight: 600;
            font-size: 1.1rem;
          }
        }
      }
    }
  }

  .kt-widget__bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgb(235, 237, 242);
    margin-top: 1rem;

    .kt-widget__item {
      display: flex;
      align-items: center;
      flex-grow: 1;
      padding: 2rem 0 0 1.5rem;

      .kt-widget__icon i {
        font-size: 2.7rem;
        color: rgb(173, 177, 199);
      }

      .kt-widget__details {
        padding-right: 1rem;

        .kt-widget__title {
          display: block;
          color: rgb(167, 171, 195);
          font-weight: 600;
          font-size: 0.95rem;
        }

        .kt-widget__value {
          display: block;
          color: rgb(70, 68, 87);
          font-weight: 600;
          font-size: 1.2rem;

          span {
            color: rgb(70, 68, 87) !important;
            font-weight: 600 !important;
          }
        }

        a.kt-widget__value {
          font-size: 0.95rem;

          &:hover {
            color: rgb(93, 120, 255);
          }
        }

        .kt-section__content {
          padding-left: 0.7rem;
        }
      }
    }
  }

  .kt-widget__form {
    margin-top: 1.5rem;
  }

  .kt-widget__body .kt-widget__item {
    padding: 0.7rem 0;

    .kt-widget__contact {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.5rem;

      .kt-widget__label {
        color: rgb(70, 68, 87);
        font-weight: 600;
      }

      .kt-widget__data {
        color: rgb(167, 171, 195);
        font-weight: 400;
      }

      a.kt-widget__data:hover {
        color: rgb(93, 120, 255);
        transition: color 0.3s ease;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .kt-widget__top .kt-widget__content .kt-widget__subhead span {
    &.kt-widget__title {
      color: rgb(167, 171, 195);
      font-weight: 500;
    }

    &.kt-widget__value {
      direction: ltr;
    }
  }

  @media (max-width: 768px) {
    padding-top: 0.5rem;

    .kt-widget__top {
      display: flex;
      align-items: flex-start;

      .kt-widget__media {
        margin-top: 0.5rem;

        img {
          max-width: 60px;
        }
      }

      .kt-widget__pic {
        width: 60px;
        height: 60px;
        margin-top: 0.5rem;
        font-size: 1.3rem;
      }

      .kt-widget__content {
        padding-left: 1rem;
        margin-top: 0;

        .kt-widget__head .kt-widget__username {
          padding-bottom: 0.6rem;
        }

        .kt-widget__subhead {
          padding: 1.2rem 0;

          a:not(:first-child):not(:last-child) {
            padding: 0.5rem 1rem 0.5rem 0;
          }
        }

        .kt-widget__info {
          display: flex;

          .kt-widget__desc {
            padding-bottom: 1rem;
          }

          .kt-widget__progress {
            width: 100%;
          }
        }
      }
    }

    .kt-widget__bottom {
      padding-top: 1rem;

      .kt-widget__item {
        padding: 1rem 1rem 0 0;
        .kt-widget__icon i {
          font-size: 2.5rem;
        }
      }
    }
  }
}

.role-wrapper {
  margin-top: 0.7rem;
  text-align: center;

  .ml-2 {
    margin: 0 !important;
  }
}

.grid-divider {
  overflow-x: hidden;
  position: relative;

  & > [class*='col-']:nth-child(n + 1):not(:last-child)::after {
    content: '';
    background-color: rgb(235, 237, 242);
    position: absolute;
    top: 0;
    bottom: 0;

    @media (max-width: 767px) {
      width: 100%;
      height: 1px;
      left: 0;
      top: -4px;
    }

    @media (min-width: 768px) {
      width: 1px;
      height: auto;
      left: -1px;
    }
  }
}
